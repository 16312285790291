@import "normalize.scss";
@import "variables.scss";
@import "fira.scss";

:root {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: $text-color;
  background-color: $background-color;
  box-sizing: border-box;
}

// advice based on https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0 0.8rem;
}

a {
  color: $link-color;
  text-decoration: none;
  font-weight: 700;

  &:hover {
    color: $background-color;
    background-color: $text-color;
  }
  transition-property: color, background-color;
  transition-duration: 0.5s;

  // anchor.js
  &.anchorjs-link {
    color: $highlight-color;
    &:hover {
      color: $link-color;
    }
    background-color: inherit;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 900;
}

strong {
  font-weight: 900;
}

code {
  font-family: "Fira Code", monospace;
  // size & shape
  font-size: 0.95em;
  padding: 0.1em 0.15em;
  border-radius: 0.2em;
  // colours
  color: darken($highlight-color, 10%);
  background: linear-gradient(
    to right,
    lighten($highlight-color, 42%),
    lighten($highlight-color, 35%)
  );
  // text flow
  overflow-wrap: break-word;
  word-wrap: break-word;
}

pre > code {
  background-color: #282c34;
  border-radius: 0;
}

// add a "copy to clipboard" boxes
// adapted from https://www.aleksandrhovhannisyan.com/blog/how-to-add-a-copy-to-clipboard-button-to-your-jekyll-blog/
// and https://www.dannyguo.com/blog/how-to-add-copy-to-clipboard-buttons-to-code-blocks-in-hugo/
.code-block-header {
    display: flex;
    justify-content: flex-end;
}

.copy-code-button {
    background-color: lighten($highlight-color, 40%);
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-column-gap: 0.2rem;
    border: none;
    border-radius: 0.5rem 0.5rem 0 0;
    cursor: pointer;
    font-size: 1rem;
    padding: 0.6rem 0.8rem;
    width: 6rem;

    &::before {
        content: "copy";
    }

    &::after {
        content: "📋";
        display: block;
    }

    // This class will be toggled via JavaScript
    &.copied {
        &::before {
            content: "copied";
        }

        &::after {
            content: "✅";
        }
    }
}

kbd {
  // size & shape
  font-size: 0.95em;
  padding: 0.1em 0.15em;
  border-radius: 0.2em;
  // colours
  border: 1px solid #666;
  box-shadow: 0.15em 0.15em #aaa;
  background: white;
  text-decoration: none;
}

// main grid layout - mobile-first

.container {
  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows: auto;
  grid-template-areas:
    "header"
    "main"
    "footer";
}

// navigation (useful in header, but in other places as well)

.navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: $text-color;
  padding: 0 0.6rem;

  & > * {
    padding: 0.2rem;
  }

  .current {
    border-bottom: 5px solid $highlight-color;
  }

  .disabled {
    color: $text-grey;
    cursor: not-allowed;
  }
}

header {
  grid-area: header;

  padding: 2rem 0 1rem 0;

  nav.logo {
    a {
      font-size: 1.3rem;
      background: linear-gradient(
        to left,
        $highlight-color,
        darken($highlight-color, 15%)
      );
      color: $background-color;
      padding: 0.4rem 0.8rem;
      &:hover {
        text-decoration: underline;
      }
    }
    margin-bottom: 15px;
  }

  a {
    font-weight: 900;
  }
}

main {
  grid-area: main;
}

footer {
  grid-area: footer;
  border-top: 1px solid $highlight-color;
  color: $text-grey;

  a {
    color: $text-grey;
    font-weight: 700;
  }
}

// posts

.post-subtitle {
    font-style: italic;
    color: $text-grey;
    margin-bottom: 2em;
}

.post-date {
  font-style: italic;
  color: $text-grey;
}

.post-tag-container {
  display: flex;
  flex-wrap: wrap;
}

.post-tag {
  font-weight: 300;
  background-color: lighten($highlight-color, 40%);
  color: $highlight-color;
  padding: 0.2em;
  margin: 0 0.4rem 0.4rem 0;
}

.post-excerpt {
  border-top: 1px solid $highlight-color;
}

// nice blockquotes
// from https://css-tricks.com/snippets/css/simple-and-nice-blockquote-styling/
blockquote {
  background-color: darken($background-color, 3%);
  border-left: 10px solid darken($background-color, 10%);
  padding: 1em 1em;
  margin: 1em 0;
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
  color: darken($background-color, 20%);
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.1em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}

// higlighted paragraphs

.hl-para {
  background-color: lighten($highlight-color, 40%);
  border-left: 10px solid $highlight-color;
  padding: 1em 1em;
  margin: 1em 0;
  quotes: "\201C""\201D""\2018""\2019";
}

// full-width images by default

img {
  width: 100%;
}

// tables

table {
  // filthy hacks because tables don't play nice with padding
  width: calc(100% - 1.6rem);
  margin: 0 0.8rem;

  text-align: left;
  border-collapse: collapse;
  th,
  td {
    padding: 0.5em 1em 0.5em 0;
  }
  thead th {
    border-bottom: 1px solid $highlight-color;
    vertical-align: bottom;
  }
  tbody th {
    vertical-align: top;
  }
  td {
    vertical-align: top;
  }
}

// table of contents
#toc {
  background: darken($background-color, 3%);
  clear: both;

  h2 {
    background: $text-color;
    color: $background-color;
    font-weight: 100;
    text-align: right;
    padding-right: 1em;
    margin-bottom: 0;
  }

  ul {
    margin-top: 0;
    padding: 1em;
    list-style: circle inside;
    li + li {
      margin-top: 0.2em;
    }
    a {
      font-weight: 400;
    }
  }
}

// for the bibliography (see _plugins/biblist.rb)

.bibliography {
  p {
    margin: 0.4em 0;
  }
  & > div {
    margin-bottom: 2em;
  }
  .date {
    font-weight: 300;
    color: $text-grey;
  }
  .title {
    font-weight: 900;
  }
  .author {
    font-weight: 600;
  }
  .venue {
    font-style: italic;
  }
}

// responsive design, y'all
@media (min-width: 1000px) {
  .container {
    grid-template-columns: 1fr 800px 1fr;
    grid-template-areas:
      ". header ."
      ". main ."
      ". footer .";
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    padding: 0;
  }
  table {
    width: 100%;
    margin: 0;
  }
  .navigation {
    padding: 0;

    & > * {
      padding: 0.4rem 0;
    }
  }
}

// fontawesome hacks
svg.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
  fill: currentColor;
}

// printing

@media print {
    header,footer {
        display: none;
    }
    #toc h2 {
        text-align: left;
        font-weight: 900;
        color: $text-color;
        background-color: unset;
    }
}
